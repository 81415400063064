@import "../../../../../styles/variable";
// Search form start

.search_form {
  display: grid;
  grid-template-columns: 2fr max-content;
  gap: 10px;
  width: 100%;
  background: #fff;
  padding: 10px;
  border-radius: 10px;
  z-index: 100;
  box-shadow: 0px 8px 25px rgb(150 185 255 / 25%);

  @media (max-width: 600px) {
    grid-template-columns: 1fr;
  }
  .buttons {
    display: flex;
    align-items: self-start;
    gap: 10px;
    height: 50px;
  }
}

.form_input {
  width: 100%;
  border: none;
  border-radius: 5px;
  line-height: 20px;
  font-size: 14px;
  background: #f7f7fe;
  padding: 15px;
  &:focus {
    outline: none;
  }
}

select.form_input {
  padding: 15px;
  background-color: #fff;
  box-shadow: 0px 8px 22px rgba(150, 185, 255, 0.25);
  border-radius: 5px;
}

.search_equalizer {
  background: $dark;
  height: 50px;
  border-radius: 5px;
  border: none;
  padding: 5px 10px;
  display: inline-block;
  box-shadow: $box-shadow;
  cursor: pointer;
  transition: all 0.3s;
  line-height: 1;
  &:hover {
    background-color: $brand;
  }
}

.search_submit {
  display: inline-block;
  background: $dark;
  color: $white;
  font-size: 16px;
  font-weight: 600;
  border: none;
  height: 100%;
  padding: 5px 20px;
  border-radius: 5px;
  box-shadow: 0 3px 3px 0 rgb(185 184 184 / 50%);
  cursor: pointer;
  transition: all 0.3s;
  &:hover {
    background-color: $brand;
  }
}

.search_form_input_wrapper {
  position: relative;
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 10px;
}

// Advanced search
.advanced_search_container {
  position: relative;
  width: 100%;
  grid-column: 1/-1;

  .advanced_search_select_container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
  }

  .select_wrapper {
    position: relative;
    .select_icon {
      position: absolute;
      right: 0;
      top: 0;
      width: 40px;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #fff;
      pointer-events: none;
      border-radius: 5px;
      svg {
        width: 30px;
        height: 30px;
        stroke-width: 1;
      }
    }
  }

  .advanced_search_range_container {
    margin-top: 12px;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 30px;
  }

  .range_title {
    font-weight: 500;
    text-align: left;
    padding-left: 10px;
    font-size: 12px;
    color: #3f4254;
  }

  .range_text {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px;
    color: #828282;
    font-size: 12px;
  }
}
// Search form end
.search_suggestion_wrapper {
  position: relative;
  .suggestions {
    position: absolute;
    left: calc(0px - 10px);
    top: calc(100% + 6px);
    z-index: 100;
    width: calc(100% + 20px);
    background-color: #fff;
    max-height: 220px;
    overflow: auto;
    box-shadow: 0px 8px 25px rgb(150 185 255 / 25%);
    border-radius: 0px 0px 10px 10px;
    padding: 15px;
    padding: 7px;

    /* width */
    &::-webkit-scrollbar {
      width: 5px;
      height: 6px;
      overflow: visible;
      cursor: pointer;
      background: transparent;
      border-radius: 10px;
    }
    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: rgb(202, 202, 202);
      border-radius: 10px;
    }

    ul {
      text-align: left;
      margin: 0;
      padding: 0;
      li {
        display: list-item;
        margin: 5px;
        position: relative;
        overflow: hidden;
        font-size: 14px;
        cursor: pointer;
        padding-right: 50px;
        span {
          right: 0;
          top: 0;
          position: absolute;
          opacity: 0.5;
          font-size: 80%;
        }
        strong {
          font-weight: 400;
          opacity: 0.9;
          display: inline-block;
          width: 100%;
          white-space: nowrap;
          overflow: hidden !important;
          text-overflow: ellipsis;
        }
        &:hover > strong {
          opacity: 1;
          color: $brand;
        }
        &:hover > span {
          color: $brand;
        }
      }
    }
  }
}

.location {
  display: inline-block;
  position: relative;
  .locationList {
    list-style: none;
    width: 100%;
    border: none;
    border-radius: 5px;
    line-height: 20px;
    font-size: 14px;
    background: #e7e7e7;
    padding: 10px;
    min-height: 50px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
  .loc_input {
    display: inline-block;
    border: none;
    padding: 5px;
    background: transparent;
    max-width: 110px;
  }
  .listItem {
    position: relative;
    display: inline-block;
    margin-right: 3px;
    border: 1px solid #ddd;
    padding: 3px 10px 3px 5px;
    border-radius: 3px;
    font-size: 13px;
    line-height: 1;
    white-space: nowrap;
    background: #f5f5f5;
    margin: 1px;

    span {
      display: inline-block;
      max-width: 50px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
  .closeBtn {
    border: none;
    background: transparent;
    position: absolute;
    top: -6px;
    right: -5px;
    border-radius: 50%;
    background: #e7e7e7;
    padding: 1px;
    line-height: 1;
    width: 15px;
    height: 15px;
    color: #8d8d8d;
    -webkit-box-shadow: 1px 1px 10px 5px rgba(0, 0, 0, 0.05);
    box-shadow: 1px 1px 10px 5px rgba(0, 0, 0, 0.05);
    z-index: 2;
  }
}
.Locsuggestions {
  position: absolute;
  left: calc(0px - 11px);
  top: calc(100% + 5px);
  z-index: 100;

  width: 331px;
  width: calc(100% + 142px);
  background-color: #fff;
  padding: 7px;
  max-height: 220px;
  overflow: auto;
  box-shadow: 0px 8px 25px rgb(150 185 255 / 25%);
  border-radius: 0px 0px 10px 10px;

  /* width */
  &::-webkit-scrollbar {
    width: 5px;
    height: 6px;
    overflow: visible;
    cursor: pointer;
    background: transparent;
    border-radius: 10px;
  }
  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: rgb(202, 202, 202);
    border-radius: 10px;
  }

  ul {
    text-align: left;
    margin: 0;
    padding: 0;
    li {
      display: list-item;
      margin: 5px;
      position: relative;
      overflow: hidden;
      font-size: 14px;
      cursor: pointer;
      padding-right: 50px;
      span {
        right: 0;
        top: 0;
        position: absolute;
        opacity: 0.5;
        font-size: 80%;
      }
      strong {
        font-weight: 400;
        opacity: 0.9;
        display: inline-block;
        width: 100%;
        white-space: nowrap;
        overflow: hidden !important;
        text-overflow: ellipsis;
      }
      &:hover > strong {
        opacity: 1;
        color: $brand;
      }
      &:hover > span {
        color: $brand;
      }
    }
  }
}

.range_wrapper {
  position: relative;
  .range_slide {
    padding: 0 10px;
  }
}

//
@media (max-width: 600px) {
  .search_form_container {
    margin-top: 30px;
  }

  // Search form
  .search_form {
    .buttons {
      margin-top: 10px;
    }
  }
  .search_form_input_wrapper {
    grid-template-columns: 1fr;
    gap: 10px;
  }
  .advanced_search_container {
    .advanced_search_select_container {
      grid-template-columns: 1fr;
    }
    .advanced_search_range_container {
      width: 100%;
      grid-template-columns: 1fr;
      gap: 0px;
    }
    .select_wrapper {
      margin-right: 0 !important;
    }
  }
}
.disable_btn {
  pointer-events: none;
  background: #7a7a7a !important;
  border: 1px solid #7a7a7a !important;
  cursor: not-allowed !important;
}
